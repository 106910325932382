* {
    font-family: "Fira Sans",sans-serif;
    margin: 0;
    padding: 0;
}
a.visited {
    color: inherit;
}
a {
    color: inherit;
    text-decoration: none;
}
ul {
    left: 0;
    list-style: outside none none;
    margin: 0 auto;
    position: relative;
    right: 0;
}
body {
    background-image: url("../img/noisebackground.png");
    background-repeat: repeat;
	background-color:#e0e0e0;
}
body > div {
    background-color: #ddd;
}
header.smaller nav {
    bottom: 5px;
    color: #fff;
}
header.smaller h2 {
    display: none;
}
header.smaller h1 {
    top:0 !important;
}
header.smaller img {display:none;}
header.smaller {
    height: 75px;
    padding-top: 10px;
}
header {
    background-color: #039be5;
    box-shadow: 2px 0 3px #666;
    color: #fff;
    height: 180px;
    left: 0;
    min-width: 1090px;
    overflow: hidden;
    padding-top: 35px;
    position: fixed;
    text-align: center;
    top: 0;
    transition: all 0.3s ease 0s;
    width: 100%;
    z-index: 10;
}
header nav {
    bottom: 10px;
    left: 0;
    margin: 5px auto;
    position: absolute;
    right: 0;
}
header nav li:hover {
    text-shadow: 1px 1px 15px #fff;
}
header nav li + li {
    border-left: 2px solid #0277bd;
}
header nav li {
    color: #eee;
    display: inline-block;
    width: 140px;
}
div.header {
    margin-left: 75px;
}
div.mainWrapper {
    background-color: transparent;
    padding: 2px 10px 10px;
    position: relative;
    top: 230px;
}
div.center {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
    min-width: 1000px;
    position: relative;
    right: 0;
}

div.maincard div.siteHeader
{
	height:110px;
	border-bottom: 2px solid #eee;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
    text-shadow: 1px 0 3px #000;

	background-color:#039be5;
	background-position:center;
	background-repeat:no-repeat;
}

div.maincard h1.overSiteHeader {
    background-color: #039be5;
    border-bottom: 3px solid #0277bd;
    color: #01579b;
    font-size: 14px;
    height: 20px;
    margin-bottom: 0;
    margin-top: 5px;  
    padding-top: 5px;
    text-align: center;
	font-weight:normal !important;
}
div.maincard h1.siteHeader {
	
	position:relative;
	top:75px;
    
}
div.maincard img.siteHeader {
    vertical-align:bottom;
	margin-left:-50px;
}

div.maincard h2.siteHeader 
{	
	color:#fff;
	text-align:center;	
	overflow:hidden;
	font-size:28px;
	position:relative;
	top:75px;	
}
div.maincard p.siteHeader
{
	text-align:center;
	border-top:2px solid #01579b;
	border-bottom:2px solid #01579b;
	background-color:#0288d1;
	color:#fff;
	box-shadow:-2px 0 4px #999;	
}

div.maincard {
    background-color: #fff;
    box-shadow: 7px 5px 10px #ddd;
    left: 230px;
    
    min-width: 640px;
    position: absolute;
    width: 75%;
}
div.standAlone
{
	left:175px;	
}
div.subMenu li img 
{
	height:20px;
	float:right;
	margin-right:5px;	
	transition: all 0.8s ease 0s;
	opacity:0;
}

div.subMenu ul ul li.umwelt:hover{background-color:#7cb342; border-bottom:2px solid #689f38;}
div.subMenu ul ul li.energie:hover{background-color:#fdd835; border-bottom:2px solid #fbc02d;}
div.subMenu ul ul li.arbsich:hover{background-color:#e53935; border-bottom:2px solid #c62828;}
div.subMenu ul ul li.datenschutz:hover{background-color:#8e24aa; border-bottom:2px solid #7b1fa2;}
div.subMenu ul ul li.quali:hover{background-color:#1e88e5; border-bottom:2px solid #1976d2;}
div.subMenu ul ul li.gesund:hover{background-color:#43a047; border-bottom:2px solid #388e3c;}
div.subMenu ul ul li.exservice:hover{background-color:#111; border-bottom:2px solid #000; color:#fff;}

div.subMenu ul ul li:hover {padding-left:40px; text-shadow:1px 0 3px #fff;}
div.subMenu ul ul li
{
	transition: all 0.8s ease 0s;
	padding-left:35px;	
}
div.subMenu li {
    border-bottom: 2px solid #eee;
    padding-top: 5px;
    transition: all 0.3s ease 0s;
	padding-top:2px;
	padding-left:15px;
}
div.subMenu li:hover img {opacity:0.99;}
div.subMenu li:hover {
    padding-left: 25px;
}
div.subMenu {
    background-color: #fff;
    box-shadow: 0 5px 9px #eee;
    color: #333;
    height: 430px;
    margin-top: 11px;
    padding-right: 0;
    padding-top: 80px;
    position: absolute;
    text-shadow: 0 0 4px #999;
    top: -9px;
    width: 210px;
	border-radius:4px;
}
div.content p {text-align:center;}
div.content {
	margin:10px;
	box-shadow:1px 0px 3px #aaa;
	border-radius:5px;
	padding-bottom:20px;
}
li.linkCard + li.linkCard {
    margin-top: 10px;
}
li.linkCard {
    background-color: #fff;
    box-shadow: 1px 0 3px #aaa;
    color: #333;

    padding: 5px;
    text-align: center;	
	border-radius:10px;
	margin:5px 10px;	
}
li.linkCard:hover img {opacity:0.99;}
li.linkCard img 
{
	height:30px;	
	margin-left:30px;	
	transition: all 0.8s ease 0s;
	opacity:0;
	vertical-align:middle;
}

ul.oldSchoolList, ul.komplexList
{
	box-shadow: 1px 0 5px #ccc;
    list-style: inside none square;
    width: 400px;
	background-color:#555;	
	background-image:url(../img/noisebackground.png);
	padding-bottom:10px;
	color:#ffb300;
	border-radius:10px;
}

ul.oldSchoolList li , ul.komplexList li
{
	text-align:left;
	padding:5px;
	left:0;
	right:0;
	margin:0 auto;	
	
	background-repeat:repeat;
	border-bottom:1px solid #777;
}

ul.komplexList li {padding:2px 15px;}
ul.komplexList {width:600px;}
ul.komplexList ul
{
	list-style:circle;	
	list-style:inside;
}
ul.komplexList ul li
{
	border-bottom:none;
}
li.kategorie
{
	color:#333 !important;
	text-shadow:1px 0 3px #ccc !important;
	text-align:center !important;
	background-color:#fff !important;
	list-style:none !important;
}

div.widecard p.cardHeading
{
	padding:10px 5px;
	background-color:#039be5;
	border-top:2px solid #0277bd;	
	color:#fff;
}
div.widecard
{
	box-shadow:1px 0 4px #aaa;
	width:700px;
	border-radius:10px;
	left:0;
	right:0;
	margin:0 auto;
}
div.widecard p.info
{
    border-radius: 5px;
    font-size: 14px;
    left: 50px;
    padding: 10px;
    position: relative;
    top: 10px;
    width: 450px;
	text-align:left;
}
div.widecard img.pic
{
	border: 3px solid #ccc;
    bottom: 162px;
    box-shadow: 1px 0 3px #999;
    left: 555px;
    position: relative;
}
p.cardSubHeading
{
	background-color:#039be5;
	border-bottom: 2px solid #01579b;
    font-size: 16px;
    padding:3px;
    text-align: left !important;
    color:#fff;
	height:20px;
}
ul#zustandigkeiten
{
	width:475px;
	font-size:14px;
	padding:15px;
	list-style:inside circle;
}
ul#qualifikationen li {padding-bottom:3px;}
ul#qualifikationen
{
	width:475px;
	font-size:14px;
	padding:15px;
	list-style:inside disc;	
}
div.box
{
	box-shadow: 1px 0px 3px #aaa;
	width: 490px;
	left: 0px;
	right: 0px;
	margin: 10px auto;	
	border-radius:4px;
}
div.widebox h2 {font-size:18px; text-align:center; font-weight:normal; border-bottom:2px solid #999; margin-bottom:5px; padding:5px;}
div.widebox
{
	box-shadow: 1px 0px 3px #aaa;
	left: 0px;
	right: 0px;
	margin: 10px auto;	
	border-radius:4px;
	background-color:#fafafa;
	width:90%;
	font-size:14px;
	padding-bottom:10px;
}

table#refUbersicht td {border-bottom:2px solid #ddd;}
table#refUbersicht td {padding:5px 15px 2px 30px;}
table#refUbersicht th {height:30px; background-color:#01579b;color:#fff; text-shadow:1px 0 3px #333; border-top:10px solid #fafafa; border-bottom:5px solid #fafafa;}
table#refUbersicht th.tableTop { border-bottom:5px solid #fafafa;}	
table#refUbersicht
{
	width:500px;
	left:0;right:0; margin:20px auto;	
}


div.refBox div.imgLogo img {}
div.refBox div.imgLogo
{
    border: 4px solid #fff;
    box-shadow: 1px 0 3px #000;
	display:inline-block;
/*    height: 200px;
    width: 370px;*/	
	margin: 20px;
}
div.refBox
{
    background-color: #ddd;
    
    box-shadow: 2px 2px 4px #999;
    left: 0;
    margin: 20px auto;
    min-height: 270px;
    padding-bottom: 10px;
    right: 0;
    width: 90%;	
	border-top-left-radius:6px;
	border-top-right-radius:6px;
}
p.refGroup
{
	padding:5px;
	font-size: 18px;
    position: relative;
    top: 0;	
	text-shadow:1px 0 3px #fff;
	border-top-left-radius:6px;
	border-top-right-radius:6px;
}

p.umwelt {background-color:#7cb342; border-bottom:2px solid #689f38;}
p.energie {background-color:#fdd835; border-bottom:2px solid #fbc02d;}
p.arbsich {background-color:#e53935; border-bottom:2px solid #c62828;}
p.datenschutz {background-color:#8e24aa; border-bottom:2px solid #7b1fa2;}
p.quali {background-color:#1e88e5; border-bottom:2px solid #1976d2;}
p.gesund {background-color:#43a047; border-bottom:2px solid #388e3c;}
p.exservice {background-color:#111; border-bottom:2px solid #000; color:#fff;}

div.widecard p.ing_info
{
    border-radius: 5px;
    font-size: 14px;
    padding: 10px;
    position: relative;
    top: 10px;
	text-align:left;
	margin:10px 20px;
	color:#1a237e;
}

th.umwelt {background-color:#7cb342 !important; border-bottom:2px solid #689f38 !important;}
th.energie {background-color:#fdd835 !important; border-bottom:2px solid #fbc02d !important;}
th.arbsich {background-color:#e53935 !important; border-bottom:2px solid #c62828 !important;}
th.datenschutz {background-color:#8e24aa !important; border-bottom:2px solid #7b1fa2 !important;}
th.quali {background-color:#1e88e5 !important; border-bottom:2px solid #1976d2 !important;}
th.gesund {background-color:#43a047 !important; border-bottom:2px solid #388e3c !important;}
th.exservice {background-color:#111 !important; border-bottom:2px solid #000 !important; color:#fff !important;}

table.kontakt input
{
	width:200px;
	height:20px;	
}
table.kontakt td {border-bottom:2px solid #eee; padding:5px;}
table.kontakt
{
	width: 400px; left: 0px; right: 0px; margin: 10px auto;
	font-size:12px;
	border-collapse:collapse;
	border-bottom:10px solid #fff;
}